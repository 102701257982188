export const environment = {
  production:true,
  development:false,
  apiendpoint: 'https://maxsoldcontractapi.azurewebsites.net/v1/',
  tagManagerId: 'GTM-NTLW36',
  mapsAPI: 'AIzaSyDKpD_fWfp0-Tw_SopAoNkmWvTrOaAwnuQ',
  firebase: {
    apiKey: 'AIzaSyDKpD_fWfp0-Tw_SopAoNkmWvTrOaAwnuQ',
    authDomain: 'client.maxsold.com',
    projectId: 'contracts-new-prod',
    storageBucket: 'contracts-new-prod.appspot.com',
    messagingSenderId: '1011624041240',
    appId: '1:1011624041240:web:13d86f029d1f59f0761c6d',
    measurementId: 'G-QJ32E03ZVZ'
  }
};
